// for social buttons
@mixin social-buttons-color ($color, $state-color) {
  background-color: $color;
  color: $white;

  &:focus,
  &:hover {
    background-color: $state-color;
    color: $white;
  }
  &:active,
  &:focus,
  &:active:focus {
    box-shadow: none;
  }

  &.btn-simple {
    color: $state-color;
    background-color: transparent;
    background-image: none !important;
    box-shadow: none;
    border: none;

    &:hover,
    &:focus,
    &:hover:focus,
    &:active,
    &:hover:focus:active {
      color: $state-color;
      background: transparent !important;
      box-shadow: none !important;
    }
  }


  &.btn-neutral {
    color: $color;
    background-color: $white;

    &:hover,
    &:focus,
    &:active {
      color: $state-color;
    }
  }
}
