$page-header-padding:             0 !default;
$page-header-position:            relative !default;
$page-header-overflow:            hidden !default;
$page-header-display:             flex !default;
$page-header-align-items:         center !default;
$page-header-bg-size:             cover !default;
$page-header-bg-position:         50% !default;

$mask-position:                   absolute !default;
$mask-bg-size:                    cover !default;
$mask-bg-position:                center center !default;
$mask-top:                        0 !default;
$mask-left:                       $mask-top !default;
$mask-width:                      100% !default;
$mask-height:                     $mask-width !default;
$mask-opacity:                    .8 !default;

$page-header-conteiner-index:     1 !default;

$header-oblique-img-width:        60% !default;
$header-oblique-img-right:        -10rem !default;
$header-oblique-transform:        skewX(-10deg) !default;
$header-oblique-overflow:         hidden !default;
$header-oblique-img-transform:    skewX(10deg) !default;
