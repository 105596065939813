$icon-shape-bg-image:               linear-gradient(310deg,#7928CA,#FF0080) !default;
$icon-shape-bg-position:            center !default;

$icon-striped-bg-md:                85px !default;
$icon-striped-bg-lg:                111px !default;
$icon-striped-bg-xl:                80px !default;

$icon-striped-icon-mt:              25% !default;
$icon-striped-icon-ml:              -24% !default;

$icon-shape-icon-opacity:           .8 !default;
$info-icon-top:                     11px !default;
$info-icon-top-xxs:                 -4px !default;
$info-icon-top-xs:                  -4px !default;
$info-icon-top-sm:                  2px !default;
$info-icon-top-md:                  22% !default;
$info-icon-top-lg:                  31% !default;
$info-icon-top-xl:                  37% !default;
$info-icon-position:                relative !default;

$icon-xxs-width:                    20px !default;
$icon-xxs-height:                   $icon-xxs-width !default;
$icon-xs-width:                     24px !default;
$icon-xs-height:                    $icon-xs-width !default;
$icon-sm-width:                     32px !default;
$icon-sm-height:                    $icon-sm-width !default;
$icon-md-width:                     48px !default;
$icon-md-height:                    $icon-md-width !default;
$icon-lg-width:                     64px !default;
$icon-lg-height:                    $icon-lg-width !default;
$icon-xl-width:                     100px !default;
$icon-xl-height:                    $icon-xl-width !default;
